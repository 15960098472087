import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';
import {
    AIContentGenerationResponse,
    AIContentGenerationResponseDetail,
    AIMessage,
    AIMessageType,
    AIRole,
} from './ai.model';
import { Delta } from 'quill/core';

export function toAIMessage(result: any): AIMessage {
    return {
        id: result.answer.id,
        role: AIRole.MODEL,
        message: result.answer.message,
        messageType: AIMessageType.STANDARD,
        timestamp: result.answer.creationTimestamp,
        alreadyAnimated: false,
    };
}

export function toAIContentGenerationContents(
    result: any[],
): AIContentGenerationResponse {
    const converter = new Server2Delta();
    return {
        contents: result.map(({ text, toneType }) => {
            const detail: AIContentGenerationResponseDetail = {
                text: converter.process(text) ?? new Delta(),
                toneType,
            };

            return detail;
        }),
    };
}
