import { NgModule } from '@angular/core';
import {
    IconButtonComponent,
    ImageComponent,
    ProgressLoaderComponent,
    SeparatorComponent,
} from '@interacta-shared/ui';
import { IconComponent } from '@interacta-shared/ui-icons';
import { AttachmentInputService } from '@modules/shared-v2/services/attachment-input.service';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { UploadAttachmentItemComponent } from './components/upload-attachment-item/upload-attachment-item.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';

export const UPLOAD_ATTACHMENT_INPUT_SERVICE =
    'UPLOAD_ATTACHMENT_INPUT_SERVICE';

const standaloneComponents = [
    IconComponent,
    IconButtonComponent,
    ImageComponent,
    SeparatorComponent,
    ProgressLoaderComponent,
];
@NgModule({
    declarations: [UploadDialogComponent, UploadAttachmentItemComponent],
    imports: [SharedV2Module, ...standaloneComponents],
    exports: [UploadDialogComponent],
    providers: [
        {
            provide: UPLOAD_ATTACHMENT_INPUT_SERVICE,
            useClass: AttachmentInputService,
        },
    ],
})
export class UploadDialogModule {}
