import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { fadeInOutAnimation } from '@interacta-shared/ui';
import { Index } from '@interacta-shared/util';
import { ThemeMode } from '@interacta-shared/util-common';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-ai-panel-chat-attachment-list',
    templateUrl: './ai-panel-chat-attachment-list.component.html',
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInOutAnimation('selectionOverlay')],
})
export class AIPanelChatAttachmentListComponent {
    @Input({ required: true }) attachments!: IListAttachments;
    @Input({ required: true }) title!: string;
    @Input({ required: true }) theme!: ThemeMode;
    @Input() selectedAttachment: Index | null = null;

    @Output() selectAttachment = new EventEmitter<IAttachment>();
    @Output() loadMore = new EventEmitter<void>();

    hoveringAttachment: IAttachment | null = null;

    isSelected(data: {
        attachment: IAttachment;
        selectedAttachment: Index | null;
    }): boolean {
        return data.attachment.id === data.selectedAttachment;
    }

    isHovering(data: {
        attachment: IAttachment;
        hoveringAttachment: IAttachment | null;
    }): boolean {
        return data.attachment.id === data.hoveringAttachment?.id;
    }
}
