<article
    *ngIf="{
        openFeedbackDetail: openFeedbackDetail$ | async,
        triggerFeedbackSuccessfulMessage:
            triggerFeedbackSuccessfulMessage$ | async,
    } as $"
>
    <section class="bg-surface-A rounded-2xl p-8">
        @if (canCollapse || title) {
            <div class="flex h-20 items-center justify-between">
                @if (title) {
                    <p class="typo-xs text-text-mid-contrast">
                        {{ title | translate }}
                    </p>
                }
                @if (canCollapse) {
                    <button
                        interacta-icon-button
                        type="button"
                        [attr.aria-label]="
                            (collapsed
                                ? 'AI.CONTENT_GENERATION.EXPAND_SECTION'
                                : 'AI.CONTENT_GENERATION.COLLAPSE_SECTION'
                            ) | translate
                        "
                        [icon]="
                            collapsed
                                ? 'chevron-small-down'
                                : 'chevron-small-up'
                        "
                        [matTooltip]="
                            (collapsed
                                ? 'AI.CONTENT_GENERATION.EXPAND_SECTION'
                                : 'AI.CONTENT_GENERATION.COLLAPSE_SECTION'
                            ) | translate
                        "
                        [size]="'extra-small'"
                        (click)="collapsed = !collapsed"
                    ></button>
                }
            </div>
        }

        @if (hasErrors) {
            <p>{{ 'AI.ERROR' | translate }}</p>
        } @else {
            <interacta-delta-view
                class="typo-sm block size-full"
                [delta]="content"
                [maxLines]="collapsed ? 1 : 0"
                [ngClass]="{
                    'mt-8': canCollapse || title,
                }"
            />
        }

        @if (!collapsed) {
            <interacta-separator class="my-8 block" [thickness]="'soft'" />
            <div
                class="flex items-center gap-x-12"
                [ngClass]="{
                    'justify-end': hasErrors,
                }"
            >
                @if (!hasErrors) {
                    <button
                        interacta-icon-button
                        type="button"
                        [active]="like === true"
                        [attr.aria-label]="'AI.LIKE' | translate"
                        [icon]="'thumb-up'"
                        [matTooltip]="'AI.LIKE' | translate"
                        (click)="sendLike(true)"
                    ></button>
                    <button
                        interacta-icon-button
                        type="button"
                        [active]="like === false"
                        [attr.aria-label]="'AI.DISLIKE' | translate"
                        [icon]="'thumb-down'"
                        [matTooltip]="'AI.DISLIKE' | translate"
                        (click)="sendLike(false)"
                    ></button>
                    <button
                        interacta-icon-button
                        type="button"
                        [attr.aria-label]="'AI.COPY' | translate"
                        [icon]="'copy'"
                        [matTooltip]="'AI.COPY' | translate"
                        (click)="copy()"
                    ></button>
                    <button
                        class="ml-auto"
                        interacta-button
                        type="button"
                        [active]="true"
                        [appearance]="'ghost'"
                        [label]="'AI.CONTENT_GENERATION.CONFIRM' | translate"
                        [size]="'regular'"
                        (click)="confirmClicked.emit()"
                    ></button>
                } @else {
                    <button
                        interacta-button
                        type="button"
                        [active]="true"
                        [appearance]="'ghost'"
                        [label]="'AI.RETRY' | translate"
                        [size]="'regular'"
                        (click)="retryClicked.emit()"
                    ></button>
                }
            </div>
        }
    </section>

    @if ($.triggerFeedbackSuccessfulMessage) {
        <section
            class="bg-surface-A py-18 mt-8 flex w-full items-center gap-x-16 rounded-2xl px-16"
        >
            <interacta-icon
                class="text-system-success size-24 shrink-0"
                [icon]="'check'"
            />
            <p class="typo-sm grow">
                {{ 'AI.FEEDBACK.THANKS_FOR_YOUR_FEEDBACK' | translate }}
            </p>
            <button
                class="shrink-0"
                interacta-icon-button
                type="button"
                [icon]="'close'"
                [size]="'regular'"
                [style]="'ghost'"
                (click)="feedbackDetailSent$.next(false)"
            ></button>
        </section>
    }
    @if ($.openFeedbackDetail) {
        <interacta-ai-feedback-detail
            class="mt-8 block w-full"
            [feedback]="like ? 'positive' : 'negative'"
            (closeClicked)="openFeedbackDetail$.next(false)"
            (sendFeedbackDetail)="sendDetail($event)"
        />
    }
</article>
