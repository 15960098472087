import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    signal,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    AIFeedbackDetailType,
    FeedbackChip,
    negativeChips,
    positiveChips,
} from '@modules/ai/models/ai.model';

import { Subject } from 'rxjs';

@Component({
    selector: 'interacta-ai-feedback-detail',
    templateUrl: './ai-feedback-detail.component.html',
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIFeedbackDetailComponent implements OnChanges, OnDestroy {
    @Input({ required: true }) feedback!: 'positive' | 'negative';

    @Output() sendFeedbackDetail = new EventEmitter<{
        chips: FeedbackChip[];
        moreDetails: string | null;
    }>();
    @Output() closeClicked = new EventEmitter<void>();

    availableChips = signal<FeedbackChip[]>([]);
    moreDetails = new FormControl<string>('');

    destroy$ = new Subject<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.feedback) {
            this.availableChips.set([
                ...(changes.feedback.currentValue === 'positive'
                    ? positiveChips
                    : negativeChips),
            ]);
            this.moreDetails.setValue(null);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    toggleChip(id: AIFeedbackDetailType): void {
        this.availableChips.update((value) =>
            value.map((c) =>
                c.id === id ? { ...c, selected: !c.selected } : c,
            ),
        );
    }

    sendFeedbackClicked(): void {
        this.sendFeedbackDetail.emit({
            chips: this.availableChips().filter((c) => c.selected),
            moreDetails: this.moreDetails.value,
        });
    }

    atLeastOnSelected(availableChips: FeedbackChip[]): boolean {
        return availableChips.some((c) => c.selected);
    }
}
