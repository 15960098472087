import { Type } from '@angular/core';
import {
    BottomMenuItem,
    BottomMenuItemType,
} from '../models/bottom-menu-state.model';

export function componentsIncludeType(
    components: BottomMenuItem[],
    type: Type<BottomMenuItemType>,
): boolean {
    return components.some((c) => c.type === type);
}
