import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'interacta-upload-attachment-item[attachment]',
    templateUrl: './upload-attachment-item.component.html',
    styles: [],
})
export class UploadAttachmentItemComponent {
    @Input() attachment!: IAttachment;
    @Output() retryUpload = new EventEmitter<void>();
    @Output() cancelUpload = new EventEmitter<void>();

    hoverRow$ = new BehaviorSubject<boolean>(false);
    hoverSuccessIcon$ = new BehaviorSubject<boolean>(false);
    hoverError$ = new BehaviorSubject<boolean>(false);
}
