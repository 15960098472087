<article
    class="bg-surface-100 border-text flex w-full flex-col gap-y-16 rounded-2xl border-2 p-16"
>
    <section class="typo-sm-bold flex items-center justify-between gap-x-8">
        <p>{{ 'AI.FEEDBACK.FEEDBACK_SENT_TELL_US_MORE' | translate }}</p>
        <interacta-icon
            class="text-text-primary h-20 w-20"
            [icon]="feedback === 'positive' ? 'thumb-up' : 'thumb-down'"
        />
        <p>({{ 'AI.FEEDBACK.OPTIONAL' | translate }})</p>
        <button
            class="ml-auto"
            interacta-icon-button
            type="button"
            [attr.aria-label]="'SHARED.BUTTON.CLOSE' | translate"
            [icon]="'close'"
            [matTooltip]="'SHARED.BUTTON.CLOSE' | translate"
            [matTooltipPosition]="'above'"
            [size]="'small'"
            (click)="closeClicked.emit()"
        ></button>
    </section>
    <div class="flex flex-wrap gap-8">
        <interacta-chip-choice
            *ngFor="let chip of availableChips()"
            [attr.aria-label]="chip.label | translate"
            [bgColor]="chip.selected ? 'primary-300' : 'gray-300'"
            [icon]="chip.selected ? 'check' : undefined"
            [label]="chip.label | translate"
            (toggle)="toggleChip(chip.id)"
        ></interacta-chip-choice>
    </div>
    <div>
        <interacta-form-field-v2
            [control]="moreDetails"
            [name]="'AI.FEEDBACK.ADD_SOMETHING_ELSE' | translate"
        >
            <interacta-input-text-v2 [control]="moreDetails">
            </interacta-input-text-v2>
        </interacta-form-field-v2>
    </div>
    <section
        *ngIf="{
            sendButtonActive:
                !!moreDetails.value ||
                (availableChips() | apply: atLeastOnSelected),
        } as $$"
        class="mt-8 flex justify-end"
    >
        <button
            interacta-button
            type="button"
            [active]="$$.sendButtonActive"
            [appearance]="$$.sendButtonActive ? 'fill' : 'ghost'"
            [attr.aria-label]="'AI.FEEDBACK.SEND' | translate"
            [bgColor]="$$.sendButtonActive ? 'primary' : 'textColor'"
            [label]="'AI.FEEDBACK.SEND' | translate"
            [size]="'regular'"
            (click)="sendFeedbackClicked()"
        ></button>
    </section>
</article>
