import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TipService } from '@interacta-shared/feature-tip';
import { FeedbackChip } from '@modules/ai/models/ai.model';
import { fromDeltaToPlain } from '@modules/core/helpers/delta/delta-mention.util';
import { Delta } from 'quill/core';
import {
    BehaviorSubject,
    concat,
    distinctUntilChanged,
    map,
    Observable,
    of,
    skip,
    switchMap,
    timer,
} from 'rxjs';

@Component({
    selector: 'interacta-ai-panel-content-generation-detail-card',
    templateUrl: './ai-panel-content-generation-detail-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIPanelContentGenerationDetailCardComponent
    implements OnInit, OnChanges
{
    @Input({ required: true }) content!: Delta | null;
    @Input({ required: true }) like?: boolean;
    @Input() title: string | null = null;
    @Input() canCollapse = false;
    @Input() hasErrors = false;

    @Output() confirmClicked = new EventEmitter<void>();
    @Output() retryClicked = new EventEmitter<void>();
    @Output() sendFeedback = new EventEmitter<boolean>();
    @Output() sendFeedbackDetail = new EventEmitter<{
        chips: FeedbackChip[];
        moreDetails: string | null;
    }>();

    triggerFeedbackSuccessfulMessage$!: Observable<boolean>;
    feedbackDetailSent$ = new BehaviorSubject<boolean>(false);
    openFeedbackDetail$ = new BehaviorSubject<boolean>(false);

    private readonly tipService = inject(TipService);

    collapsed = false;

    ngOnInit(): void {
        this.triggerFeedbackSuccessfulMessage$ = this.feedbackDetailSent$.pipe(
            skip(1),
            distinctUntilChanged(),
            switchMap((value) =>
                concat(of(value), timer(5000).pipe(map(() => false))),
            ),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.like) {
            this.openFeedbackDetail$.next(this.like !== undefined);
        }
    }

    sendLike(like: boolean): void {
        if (this.feedbackDetailSent$.value) {
            this.feedbackDetailSent$.next(false);
        }

        this.sendFeedback.emit(like);
    }

    sendDetail(detail: {
        chips: FeedbackChip[];
        moreDetails: string | null;
    }): void {
        this.feedbackDetailSent$.next(true);
        this.openFeedbackDetail$.next(false);

        this.sendFeedbackDetail.emit(detail);
    }

    copy(): void {
        if (!this.content) return;

        const plain = fromDeltaToPlain(this.content);
        navigator.clipboard.writeText(plain);
        this.tipService.success('AI.COPY_SUCCESS');
    }
}
