import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UploadState } from './upload.reducer';

export const getUploadFeature =
    createFeatureSelector<UploadState>('uploadState');

export const selectHashtagsToAttach = createSelector(
    getUploadFeature,
    (state) => state.hashtagsToAttach,
);
