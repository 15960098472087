import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    signal,
    SimpleChanges,
} from '@angular/core';
import { TipService } from '@interacta-shared/feature-tip';
import { Index, isDefined } from '@interacta-shared/util';
import {
    AIContextType,
    AIMessage,
    AIMessageType,
    AIRole,
    FeedbackChip,
    MAIN_THREAD,
} from '@modules/ai/models/ai.model';
import { AIActionsAPI } from '@modules/ai/store/ai-api.actions';
import { AIActions } from '@modules/ai/store/ai.actions';
import {
    selectActiveHistory,
    selectCachedAttachment,
} from '@modules/ai/store/ai.selectors';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    BehaviorSubject,
    concat,
    concatMap,
    distinctUntilChanged,
    filter,
    from,
    ignoreElements,
    map,
    Observable,
    of,
    skip,
    Subject,
    switchMap,
    takeUntil,
    timer,
} from 'rxjs';

@Component({
    selector: 'interacta-ai-message',
    templateUrl: './ai-message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIMessageComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true }) item!: AIMessage;
    @Input({ required: true }) messageKey!: string;
    @Input({ required: true }) theme!: 'light' | 'dark';
    @Input({ required: true }) contextType!: AIContextType;
    @Input() threadId: Index = MAIN_THREAD;
    @Input() last = false;

    @Output() scrollToBottom = new EventEmitter<void>();
    @Output() retry = new EventEmitter<void>();
    @Output() openAttachmentPreview = new EventEmitter<IAttachment>();
    @Output() goToThread = new EventEmitter<Index>();

    displayedMessage = signal('');
    triggerFeedbackSuccessfulMessage$!: Observable<boolean>;
    feedbackDetailSent$ = new BehaviorSubject<boolean>(false);
    openFeedbackDetail$ = new BehaviorSubject<boolean>(false);
    messagesCount$ = new BehaviorSubject<number>(0);
    hasErrors$ = new BehaviorSubject<boolean | undefined>(undefined);
    attachmentDetail$ = new Observable<IAttachment | null>();
    destroy$ = new Subject<void>();

    protected readonly AIRole = AIRole;
    protected readonly AIMessageType = AIMessageType;
    protected readonly MAIN_THREAD = MAIN_THREAD;

    constructor(
        private store: Store,
        private actions: Actions,
        private tipService: TipService,
    ) {}

    ngOnInit(): void {
        if (
            this.item.attachmentId &&
            typeof this.item.attachmentId === 'number' &&
            this.item.messageType === AIMessageType.ATTACHMENT
        ) {
            this.attachmentDetail$ = this.store.select(
                selectCachedAttachment(this.item.attachmentId),
            );
        }

        this.actions
            .pipe(
                ofType(AIActionsAPI.sendFeedbackSuccess),
                filter((action) => action.key === this.messageKey),
                takeUntil(this.destroy$),
            )
            .subscribe((value) => {
                this.openFeedbackDetail$.next(value.like != undefined);

                if (value.like === undefined)
                    this.feedbackDetailSent$.next(false);
            });

        this.actions
            .pipe(
                ofType(AIActionsAPI.sendFeedbackError),
                filter((action) => action.key === this.messageKey),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.feedbackDetailSent$.next(true);
            });

        this.actions
            .pipe(
                ofType(
                    AIActionsAPI.sendFeedbackDetailSuccess,
                    AIActionsAPI.sendFeedbackDetailError,
                ),
                filter((action) => action.key === this.messageKey),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.feedbackDetailSent$.next(true);
                this.openFeedbackDetail$.next(false);
            });

        this.triggerFeedbackSuccessfulMessage$ = this.feedbackDetailSent$.pipe(
            skip(1),
            distinctUntilChanged(),
            filter(
                (value) =>
                    value !== undefined && this.item.role === AIRole.MODEL,
            ),
            switchMap((value) =>
                concat(of(value), timer(5000).pipe(map(() => false))),
            ),
            takeUntil(this.destroy$),
        );

        if (this.item.role === AIRole.MODEL && !this.item.alreadyAnimated) {
            const stringFormatted = this.formatMessage(this.item.message);
            from(stringFormatted.split(/(\s+)/g))
                .pipe(
                    concatMap((word) =>
                        concat(of(word), timer(30).pipe(ignoreElements())),
                    ),
                    takeUntil(this.destroy$),
                )
                .subscribe({
                    next: (word) =>
                        this.displayedMessage.update((value) => value + word),
                    complete: () => {
                        this.store.dispatch(
                            AIActions.setAlreadyAnimated({
                                clientId: this.messageKey,
                                contextType: this.contextType,
                                threadId: this.threadId,
                            }),
                        );
                        this.scrollToBottom.emit();
                    },
                });
        } else {
            this.displayedMessage.set(this.formatMessage(this.item.message));
            this.scrollToBottom.emit();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes?.item &&
            this.item.attachmentId &&
            typeof this.item.attachmentId === 'number' &&
            this.item.messageType === AIMessageType.ATTACHMENT &&
            (changes.item.isFirstChange() ||
                this.item.attachmentId !==
                    changes.item.previousValue.attachmentId)
        ) {
            this.store.dispatch(
                AIActions.getAttachmentDetail({ id: this.item.attachmentId }),
            );

            this.store
                .select(selectActiveHistory(this.item.attachmentId))
                .pipe(filter(isDefined), takeUntil(this.destroy$))
                .subscribe((value) => {
                    this.messagesCount$.next(
                        Object.keys(value.history).length - 1,
                    );
                    this.hasErrors$.next(value.hasErrors);
                });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    setLike(actualLikeValue: boolean | undefined, likeWanted: boolean): void {
        if (this.feedbackDetailSent$.value) {
            this.feedbackDetailSent$.next(false);
        }

        const like = actualLikeValue === likeWanted ? undefined : likeWanted;

        this.store.dispatch(
            AIActions.sendFeedback({
                key: this.messageKey,
                messageId: this.item.id,
                like,
                contextType: this.contextType,
                threadId: this.threadId,
            }),
        );
    }

    sendFeedbackDetail($event: {
        chips: FeedbackChip[];
        moreDetails: string | null;
    }): void {
        if (this.item.like === undefined) return;

        this.store.dispatch(
            AIActions.sendFeedbackDetail({
                key: this.messageKey,
                messageId: this.item.id,
                like: this.item.like,
                feedbacks: $event.chips?.map((c) => c.id),
                text: $event.moreDetails ?? undefined,
                contextType: this.contextType,
                threadId: this.threadId,
            }),
        );
    }

    copyText(): void {
        navigator.clipboard.writeText(this.item.message);
        this.tipService.success('AI.COPY_SUCCESS');
    }

    private formatMessage(message: string): string {
        //! Non cambiare l'ordine
        message = message
            .replace(/\*\*(.*?)\*\*/gs, '<b>$1</b>') // Sostituisce i doppie asterischi con tag <b> di apertura e chiusura
            .replace(/\* (.*?)\n/g, '<li>$1</li>') // Sostituisce * seguito da uno spazio  con <li>
            .replace(/\n/g, '<br>'); // Sostituisce \n con <br>

        return message;
    }
}
