import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    filterMap,
    Index,
    isDefined,
    lastLoading,
    objectIsEmpty,
    uuid,
} from '@interacta-shared/util';
import { ThemeMode } from '@interacta-shared/util-common';
import {
    AIContextType,
    AIMessage,
    HistoryMap,
    MAIN_THREAD,
} from '@modules/ai/models/ai.model';
import { AIActions } from '@modules/ai/store/ai.actions';
import { AIContextState } from '@modules/ai/store/ai.reducer';
import * as AISelectors from '@modules/ai/store/ai.selectors';
import { selectActiveContext } from '@modules/ai/store/ai.selectors';
import { selectPostById } from '@modules/communities/store/post/post.selectors';
import { wrapWithSpan } from '@modules/core/helpers/i18n.utils';
import * as UISelectors from '@modules/core/store/UI/ui.selector';
import {
    AttachmentCategoryType,
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { isMediaAttachment } from '@modules/post/models/attachment/attachment.utils';
import { GalleryStateService } from '@modules/state/services/gallery-state.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    combineLatest,
    concat,
    concatMap,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    Observable,
    of,
    Subject,
    switchMap,
    takeUntil,
    tap,
    timer,
} from 'rxjs';
import { AIPanelTipType } from '../ai-panel-tip/ai-panel-tip.component';

@Component({
    selector: 'interacta-ai-panel-chat',
    templateUrl: './ai-panel-chat.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIPanelChatComponent implements OnChanges, OnDestroy {
    @Input({ required: true }) threadId!: Index;
    @Input() threadName: string | null = null;
    @Input() aiPostAttachmentEnabled = false;

    @ViewChild('input') input!: ElementRef<HTMLInputElement>;
    @ViewChild('scrollContainer')
    scrollContainer?: ElementRef<HTMLDivElement>;

    readonly context$: Observable<AIContextState>;

    readonly theme$: Observable<ThemeMode>;
    readonly focusOnInput$: Observable<boolean>;
    readonly isFetching$: Observable<boolean>;
    readonly sessionId$: Observable<number>;
    readonly canAddAttachment$: Observable<boolean>;

    contextId$ = new BehaviorSubject<number | null>(null);
    contextType$ = new BehaviorSubject<AIContextType | null>(null);
    threadId$ = new BehaviorSubject<Index | null>(null);
    historyMap$ = new BehaviorSubject<HistoryMap | null>(null);
    triggerFocus$ = new BehaviorSubject(false);
    destroy$ = new Subject<void>();

    questionControl = new FormControl<string>('', { nonNullable: true });

    protected readonly MAIN_THREAD = MAIN_THREAD;
    protected readonly AIContextType = AIContextType;
    protected readonly AIPanelTipType = AIPanelTipType;
    protected readonly wrapWithSpan = wrapWithSpan;
    protected readonly objectIsEmpty = objectIsEmpty;

    constructor(
        private galleryStateService: GalleryStateService,
        private translateService: TranslateService,
        private store: Store,
    ) {
        this.theme$ = this.store
            .select(UISelectors.theme)
            .pipe(filterMap((theme) => theme?.mode ?? undefined));

        this.context$ = this.store.select(selectActiveContext).pipe(
            filter(isDefined),
            tap(({ contextId, contextType }) => {
                this.contextId$.next(contextId);
                this.contextType$.next(contextType);
            }),
        );

        this.isFetching$ = this.threadId$.pipe(
            filter(isDefined),
            switchMap((threadId) =>
                this.store.select(AISelectors.selectActiveIsFetching(threadId)),
            ),
        );

        this.sessionId$ = this.store
            .select(AISelectors.selectActiveSessionId)
            .pipe(filter(isDefined));

        this.focusOnInput$ = this.triggerFocus$.pipe(
            concatMap((focus) =>
                concat(of(focus), timer(1200).pipe(map(() => false))),
            ),
        );

        this.threadId$
            .pipe(
                mergeMap((id) =>
                    this.store.select(
                        AISelectors.selectActiveHistory(id ?? undefined),
                    ),
                ),
                filter(isDefined),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            )
            .subscribe(({ history }) => this.historyMap$.next(history));

        this.context$
            .pipe(
                takeUntil(this.destroy$),
                switchMap(() =>
                    this.store.select(AISelectors.selectActiveDraftQuestion),
                ),
                filter(isDefined),
            )
            .subscribe((value) => {
                this.questionControl.setValue(value);
            });

        this.canAddAttachment$ = combineLatest([
            this.context$,
            this.threadId$,
        ]).pipe(
            filter(isDefined),
            switchMap(([context, thread]) => {
                if (
                    this.aiPostAttachmentEnabled &&
                    context.contextId &&
                    context.contextType === AIContextType.POST &&
                    thread === MAIN_THREAD
                ) {
                    return this.store
                        .select(selectPostById(context.contextId))
                        .pipe(
                            map(
                                (post) =>
                                    (post?.mediaList.totalCount ?? 0) +
                                        (post?.documentList.totalCount ?? 0) +
                                        (post?.capabilities?.canViewComment
                                            ? (post.commentsMediaList
                                                  .totalCount ?? 0)
                                            : 0) +
                                        (post?.capabilities?.canViewComment
                                            ? (post.commentsDocumentList
                                                  .totalCount ?? 0)
                                            : 0) +
                                        (post?.filePickerMediaList.totalCount ??
                                            0) +
                                        (post?.filePickerDocumentList
                                            .totalCount ?? 0) >
                                    0,
                            ),
                        );
                } else return of(false);
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.threadId) {
            this.threadId$.next(this.threadId);
        }
    }

    ngOnDestroy(): void {
        const contextId = this.contextId$.getValue();
        const contextType = this.contextType$.getValue();
        if (this.questionControl.value && contextId && contextType) {
            this.store.dispatch(
                AIActions.saveDraftQuestion({
                    contextId,
                    contextType,
                    draftQuestion: this.questionControl.value,
                }),
            );
        }
        this.destroy$.next();
    }

    scrollToBottom(): void {
        setTimeout(() => {
            this.scrollContainer?.nativeElement.scrollTo({
                top: this.scrollContainer.nativeElement.scrollHeight + 100,
            });
        });
    }

    getHistoryArray(history: HistoryMap): { id: string; value: AIMessage }[] {
        const list: { id: string; value: AIMessage }[] = [];
        Object.entries(history).forEach(([key, value]) => {
            list.push({
                id: key,
                value: { ...value },
            });
        });
        return list;
    }

    sendRequestToAI(sessionId: number | null, question?: string): void {
        const contextId = this.contextId$.getValue();
        const contextType = this.contextType$.getValue();
        if (
            (question || this.questionControl.value.trim().length) &&
            contextId &&
            contextType
        ) {
            this.store.dispatch(
                AIActions.getResponseFromAI({
                    contextId,
                    contextType,
                    question: question ?? this.questionControl.value,
                    sessionId,
                    timestamp: new Date(),
                    clientId: uuid(),
                    threadId: this.threadId,
                    attachmentId:
                        this.threadId === MAIN_THREAD
                            ? undefined
                            : this.threadId,
                }),
            );
            this.questionControl.reset();
        }
    }

    retry(message: string): void {
        this.sendRequestToAI(null, message);
    }

    getResume(question: string): void {
        this.sendRequestToAI(null, this.translateService.instant(question));
    }

    startNewConversation(
        requestMessage: string,
        responseMessage: string,
    ): void {
        const contextId = this.contextId$.getValue();
        const contextType = this.contextType$.getValue();
        if (contextId && contextType) {
            this.store.dispatch(
                AIActions.startNewConversation({
                    contextId,
                    contextType,
                    requestMessage:
                        this.translateService.instant(requestMessage),
                    responseMessage:
                        this.translateService.instant(responseMessage),
                }),
            );
        }
        this.input.nativeElement.focus();
        this.triggerFocus$.next(true);
    }

    openAttachmentPreview(attachment: IAttachment): void {
        const attachmentList: IListAttachments = {
            attachments: [attachment],
            pageTokenInfo: lastLoading(),
            totalCount: 1,
            isLoading: false,
            isError: false,
            attachmentCategoryType: isMediaAttachment(attachment)
                ? AttachmentCategoryType.MULTIMEDIA
                : AttachmentCategoryType.DOCUMENT,
        };

        this.galleryStateService.openGallery(
            {
                feature: 'embedded',
                attachmentList,
            },
            0,
            undefined,
            undefined,
            true,
        );
    }

    goToThread(index?: Index): void {
        this.store.dispatch(
            AIActions.setActiveThreadInActiveContext({
                threadId: index ?? MAIN_THREAD,
            }),
        );
    }
}
