import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { Index } from '@interacta-shared/util';
import { Delta2Server } from '@modules/core/helpers/delta/delta-2-server.class';
import { Delta } from 'quill/core';
import { map, Observable } from 'rxjs';
import {
    toAIContentGenerationContents,
    toAIMessage,
} from '../models/ai.deserialize';
import {
    AIContentGenerationResponse,
    AIContentGenerationType,
    AIContextType,
    AIFeedbackDetailType,
    AIResponse,
    AIToneOfVoiceType,
} from '../models/ai.model';

@Injectable({ providedIn: 'root' })
export class AIService {
    private readonly baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/ai`;
    private http = inject(HttpClient);

    askQuestionToAI(
        contextId: number,
        contextType: AIContextType,
        question: string,
        sessionId: number | null,
        clientId: string,
        attachmentId?: Index,
    ): Observable<AIResponse> {
        switch (contextType) {
            case AIContextType.POST:
                return this.askPostQuestionToAI(
                    contextId,
                    question,
                    sessionId,
                    clientId,
                    attachmentId,
                );
            case AIContextType.COMMUNITY:
                return this.askCommunityQuestionToAI(
                    contextId,
                    question,
                    sessionId,
                    clientId,
                );
            case AIContextType.GUIDE:
                return this.askGuideQuestionToAI(question, sessionId, clientId);

            default:
                throw new Error(`AIService: contextType null or not managed`);
        }
    }

    askCommunityQuestionToAI(
        communityId: number,
        question: string,
        sessionId: number | null,
        clientId: string,
    ): Observable<AIResponse> {
        return this.http
            .post(`${this.baseUrl}/chat/community/${communityId}/message`, {
                question,
                sessionId,
            })
            .pipe(
                map((result: any) => ({
                    answer: toAIMessage(result),
                    sessionId: result.sessionId,
                    questionId: result.question.id,
                    clientId,
                })),
            );
    }

    askPostQuestionToAI(
        postId: number,
        question: string,
        sessionId: number | null,
        clientId: string,
        attachmentId?: Index,
    ): Observable<AIResponse> {
        return this.http
            .post(`${this.baseUrl}/chat/posts/${postId}/message`, {
                question,
                sessionId,
                attachmentIds: attachmentId ? [attachmentId] : [],
            })
            .pipe(
                map((result: any) => ({
                    answer: toAIMessage(result),
                    sessionId: result.sessionId,
                    questionId: result.question.id,
                    clientId,
                })),
            );
    }

    askGuideQuestionToAI(
        question: string,
        sessionId: number | null,
        clientId: string,
    ): Observable<AIResponse> {
        return this.http
            .post(`${this.baseUrl}/chat/guide-center/message`, {
                question,
                sessionId,
            })
            .pipe(
                map((result: any) => ({
                    answer: toAIMessage(result),
                    sessionId: result.sessionId,
                    questionId: result.question.id,
                    clientId,
                })),
            );
    }

    sendFeedbackToAI(
        messageId: number,
        like: boolean | undefined,
        feedbacks: AIFeedbackDetailType[] | undefined,
        text: string | undefined,
    ): Observable<void> {
        return this.http.post<void>(
            `${this.baseUrl}/messages/${messageId}/feedback`,
            {
                like,
                feedbacks,
                text,
            },
        );
    }

    generateContent(
        text: Delta | null,
        contentGeneratorType: AIContentGenerationType,
    ): Observable<AIContentGenerationResponse> {
        return this.http
            .post<AIContentGenerationResponse>(
                `${this.baseUrl}/chat/content-generator/message`,
                {
                    text: new Delta2Server().process(text),
                    contentGeneratorType,
                },
            )
            .pipe(
                map((result: any) =>
                    toAIContentGenerationContents(result?.contents ?? []),
                ),
            );
    }

    sendContentGeneratorFeedback(
        source: Delta | null,
        generated: Delta | null,
        feedbackId: number | null,
        contentGeneratorType: AIContentGenerationType,
        toneType: AIToneOfVoiceType | null,
        like: boolean | undefined,
        feedbacks: AIFeedbackDetailType[] | undefined,
        note: string | undefined,
        confirmed: boolean | undefined,
    ): Observable<number> {
        return this.http
            .post<number>(`${this.baseUrl}/content-generator/feedback`, {
                feedbackId,
                sourceText: new Delta2Server().process(source),
                generatedText: new Delta2Server().process(generated),
                contentGeneratorType,
                toneType,
                like,
                feedbacks,
                note,
                confirmed,
            })
            .pipe(map((result: any) => result?.feedbackId));
    }
}
