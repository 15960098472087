import { Index } from '@interacta-shared/util';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { createAction, props } from '@ngrx/store';
import {
    AIContentGenerationResponseDetail,
    AIContentGenerationType,
    AIContextType,
    AIFeedbackDetailType,
    AIMessage,
    AIMessageType,
    AIToneOfVoiceType,
    AttachmentsListType,
} from '../models/ai.model';

enum AIActionAPIType {
    SendFeedbackError = '[AI] Send Feedback Error',
    SendFeedbackSuccess = '[AI] Send Feedback Success',

    SendFeedbackDetailError = '[AI] Send Feedback Detail Error',
    SendFeedbackDetailSuccess = '[AI] Send Feedback Detail Success',

    GetAttachmentDetailError = '[AI] Get Attachment Detail Error',
    GetAttachmentDetailSuccess = '[AI] Get Attachment Detail Success',

    FetchAttachmentListError = '[AI] Fetch Attachment List Error',
    FetchAttachmentListSuccess = '[AI] Fetch Attachment List Success',
}

enum AIPostActionAPIType {
    GetPostResponseFromAISuccess = '[AI] Get Post Response From AI Success',
    GetPostResponseFromAIError = '[AI] Get Post Response From AI Error',
}

enum AICommunityActionAPIType {
    GetCommunityResponseFromAISuccess = '[AI] Get Community Response From AI Success',
    GetCommunityResponseFromAIError = '[AI] Get Community Response From AI Error',
}

enum AIGuideActionAPIType {
    GetGuideResponseFromAISuccess = '[AI] Get Guide Response From AI Success',
    GetGuideResponseFromAIError = '[AI] Get Guide Response From AI Error',
}

enum AIContentGenerationActionAPIType {
    FetchGeneratedContentSuccess = '[AI] Fetch Generated Content Success',
    FetchGeneratedContentError = '[AI] Fetch Generated Content Error',

    SendGeneratedContentFeedbackError = '[AI] Send Generated Content Feedback Error',
    SendGeneratedContentFeedbackSuccess = '[AI] Send Generated Content Feedback Success',
}

const getPostResponseFromAISuccess = createAction(
    AIPostActionAPIType.GetPostResponseFromAISuccess,
    props<{
        clientId: string;
        postId: number;
        sessionId: number | null;
        questionId: number;
        answer: AIMessage;
        threadId?: Index;
    }>(),
);

const getPostResponseFromAIError = createAction(
    AIPostActionAPIType.GetPostResponseFromAIError,
    props<{
        messageType: AIMessageType;
        postId: number;
        sessionId: number | null;
        message: string;
        threadId?: Index;
    }>(),
);

const getCommunityResponseFromAISuccess = createAction(
    AICommunityActionAPIType.GetCommunityResponseFromAISuccess,
    props<{
        clientId: string;
        communityId: number;
        sessionId: number | null;
        questionId: number;
        answer: AIMessage;
        threadId?: Index;
    }>(),
);

const getCommunityResponseFromAIError = createAction(
    AICommunityActionAPIType.GetCommunityResponseFromAIError,
    props<{
        messageType: AIMessageType;
        communityId: number;
        sessionId: number | null;
        message: string;
        threadId?: Index;
    }>(),
);

const getGuideResponseFromAISuccess = createAction(
    AIGuideActionAPIType.GetGuideResponseFromAISuccess,
    props<{
        clientId: string;
        sessionId: number | null;
        questionId: number;
        answer: AIMessage;
        threadId?: Index;
    }>(),
);

const getGuideResponseFromAIError = createAction(
    AIGuideActionAPIType.GetGuideResponseFromAIError,
    props<{
        messageType: AIMessageType;
        sessionId: number | null;
        message: string;
        threadId?: Index;
    }>(),
);

const sendFeedbackError = createAction(
    AIActionAPIType.SendFeedbackError,
    props<{
        error: any;
        key: string;
        messageId: number;
        contextType: AIContextType;
        threadId?: Index;
    }>(),
);

const sendFeedbackSuccess = createAction(
    AIActionAPIType.SendFeedbackSuccess,
    props<{
        key: string;
        messageId: number;
        like: boolean | undefined;
        contextType: AIContextType;
        threadId?: Index;
    }>(),
);

const sendFeedbackDetailError = createAction(
    AIActionAPIType.SendFeedbackDetailError,
    props<{
        error: any;
        key: string;
        messageId: number;
        contextType: AIContextType;
        threadId?: Index;
    }>(),
);

const sendFeedbackDetailSuccess = createAction(
    AIActionAPIType.SendFeedbackDetailSuccess,
    props<{
        key: string;
        messageId: number;
        like: boolean | undefined;
        feedbacks: AIFeedbackDetailType[] | undefined;
        text: string | undefined;
        contextType: AIContextType;
        threadId?: Index;
    }>(),
);

const getAttachmentDetailSuccess = createAction(
    AIActionAPIType.GetAttachmentDetailSuccess,
    props<{
        attachments: IAttachment[];
    }>(),
);

const getAttachmentDetailError = createAction(
    AIActionAPIType.GetAttachmentDetailError,
    props<{
        error: any;
        id: number;
    }>(),
);

const fetchAttachmentListSuccess = createAction(
    AIActionAPIType.FetchAttachmentListSuccess,
    props<{
        list: IListAttachments;
        entityType: AttachmentsListType;
        initial?: boolean;
    }>(),
);

const fetchAttachmentListError = createAction(
    AIActionAPIType.FetchAttachmentListError,
    props<{ error: any; entityType: AttachmentsListType }>(),
);

const fetchGeneratedContentSuccess = createAction(
    AIContentGenerationActionAPIType.FetchGeneratedContentSuccess,
    props<{
        contents: AIContentGenerationResponseDetail[];
        generatedContentType: AIContentGenerationType;
    }>(),
);

const fetchGeneratedContentError = createAction(
    AIContentGenerationActionAPIType.FetchGeneratedContentError,
    props<{ error: any; contentGenerationType: AIContentGenerationType }>(),
);

const sendGeneratedContentFeedbackError = createAction(
    AIContentGenerationActionAPIType.SendGeneratedContentFeedbackError,
    props<{
        error: any;
        contentGenerationType: AIContentGenerationType;
        toneType: AIToneOfVoiceType | null;
        contextType: AIContextType;
    }>(),
);

const sendGeneratedContentFeedbackSuccess = createAction(
    AIContentGenerationActionAPIType.SendGeneratedContentFeedbackSuccess,
    props<{
        feedbackId: number;
        like: boolean | undefined;
        feedbacks: AIFeedbackDetailType[] | undefined;
        note: string | undefined;
        contentGenerationType: AIContentGenerationType;
        toneType: AIToneOfVoiceType | null;
        contextType: AIContextType;
        confirmed: boolean | undefined;
    }>(),
);

export const AIActionsAPI = {
    sendFeedbackSuccess,
    sendFeedbackError,
    sendFeedbackDetailSuccess,
    sendFeedbackDetailError,
    getAttachmentDetailSuccess,
    getAttachmentDetailError,
    fetchAttachmentListSuccess,
    fetchAttachmentListError,
};

export const AIPostActionsAPI = {
    getPostResponseFromAISuccess,
    getPostResponseFromAIError,
};

export const AICommunityActionsAPI = {
    getCommunityResponseFromAISuccess,
    getCommunityResponseFromAIError,
};

export const AIGuideActionsAPI = {
    getGuideResponseFromAISuccess,
    getGuideResponseFromAIError,
};

export const AIContentGenerationActionsAPI = {
    fetchGeneratedContentSuccess,
    fetchGeneratedContentError,
    sendGeneratedContentFeedbackSuccess,
    sendGeneratedContentFeedbackError,
};
