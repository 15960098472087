import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import {
    ApplyPipe,
    ArrayPipe,
    ButtonComponent,
    ChipChoiceComponent,
    DeltaViewComponent,
    FormControlValueV2Pipe,
    FormFieldV2Component,
    IconButtonComponent,
    ImageComponent,
    InnerEllipsesTextComponent,
    InputTextV2Component,
    LoadMoreComponent,
    MenuComponent,
    MenuDecoratorComponent,
    SafeHtmlPipe,
    SeparatorComponent,
} from '@interacta-shared/ui';
import { InteractaDatePipe } from '@interacta-shared/ui-common';
import { IconComponent } from '@interacta-shared/ui-icons';
import { isDefined } from '@interacta-shared/util';
import { AttachmentsModule } from '@modules/attachments/attachments.module';
import { componentsIncludeType } from '@modules/bottom-menu/helpers/bottom-menu.utils';
import { BottomMenuService } from '@modules/bottom-menu/services/bottom-menu.service';
import { AppSelectors, AppState } from '@modules/core/store';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Actions, EffectsModule, ofType } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, filter, map, of, switchMap, take } from 'rxjs';
import { SharedV2Module } from '../shared-v2/shared-v2.module';
import {
    AIFabComponent,
    ANIMATION_DURATION_MS,
} from './components/ai-fab/ai-fab.component';
import { AIFeedbackDetailComponent } from './components/ai-feedback-detail/ai-feedback-detail.component';
import { AIMessageAttachmentChipComponent } from './components/ai-message-attachment-chip/ai-message-attachment-chip.component';
import { AIMessageComponent } from './components/ai-message/ai-message.component';
import { AIPanelChatAttachmentButtonComponent } from './components/ai-panel-chat-attachment-button/ai-panel-chat-attachment-button.component';
import { AIPanelChatAttachmentListComponent } from './components/ai-panel-chat-attachment-list/ai-panel-chat-attachment-list.component';
import { AIPanelChatComponent } from './components/ai-panel-chat/ai-panel-chat.component';
import { AIPanelContentGenerationDetailCardComponent } from './components/ai-panel-content-generation-detail-card/ai-panel-content-generation-detail-card.component';
import { AIPanelContentGenerationDetailComponent } from './components/ai-panel-content-generation-detail/ai-panel-content-generation-detail.component';
import { AIPanelContentGenerationComponent } from './components/ai-panel-content-generation/ai-panel-content-generation.component';
import { AIPanelOverlayComponent } from './components/ai-panel-overlay/ai-panel-overlay.component';
import { AIPanelTipComponent } from './components/ai-panel-tip/ai-panel-tip.component';
import { AIPanelComponent } from './components/ai-panel/ai-panel.component';
import { AIContextType } from './models/ai.model';
import { AIActions } from './store/ai.actions';
import { AIEffects } from './store/ai.effects';
import { AIReducer } from './store/ai.reducer';
import { selectIsPanelOpen } from './store/ai.selectors';

const components = [
    AIPanelChatAttachmentButtonComponent,
    AIPanelChatAttachmentListComponent,
    AIPanelChatComponent,
    AIPanelComponent,
    AIPanelTipComponent,
    AIFabComponent,
    AIFeedbackDetailComponent,
    AIMessageAttachmentChipComponent,
    AIMessageComponent,
    AIPanelContentGenerationComponent,
    AIPanelContentGenerationDetailComponent,
    AIPanelContentGenerationDetailCardComponent,
    AIPanelOverlayComponent,
];

const standaloneComponents = [
    ButtonComponent,
    ChipChoiceComponent,
    FormFieldV2Component,
    IconButtonComponent,
    IconComponent,
    InnerEllipsesTextComponent,
    ImageComponent,
    InputTextV2Component,
    LoadMoreComponent,
    MenuComponent,
    MenuDecoratorComponent,
    SeparatorComponent,
    DeltaViewComponent,
];
const pipes = [
    ApplyPipe,
    ArrayPipe,
    SafeHtmlPipe,
    InteractaDatePipe,
    FormControlValueV2Pipe,
];

@NgModule({
    declarations: [...components],
    imports: [
        AttachmentsModule,
        CommonModule,
        SharedV2Module,
        TranslateModule,
        MatTooltipModule,
        StoreModule.forFeature('ai', AIReducer),
        EffectsModule.forFeature([AIEffects]),
        ...standaloneComponents,
        ...pipes,
    ],
    exports: [...components],
})
export class AIModule {
    constructor(
        private bottomMenuService: BottomMenuService,
        private store: Store<AppState>,
        private configurationService: ConfigurationService,
        private communitiesStateService: CommunitiesStateService,
        private actions: Actions,
    ) {
        this.configurationService
            .getEnvironmentInfoStream()
            .pipe(
                filter(isDefined),
                switchMap((envInfo) =>
                    this.store.select(AppSelectors.selectRouteState).pipe(
                        filter(isDefined),
                        map((route) => {
                            const enabled =
                                isDefined(route) &&
                                (envInfo?.installedFeatures.aiCommunity ??
                                    false) &&
                                route.appRoute === 'community';

                            if (enabled) return route.params['communityId'];
                            else return null;
                        }),
                        switchMap((id) => {
                            if (id) {
                                return this.communitiesStateService
                                    .getCommunity(id)
                                    .pipe(
                                        map(
                                            (community) =>
                                                (community.metadata
                                                    ?.workflowDefinition &&
                                                    community.capabilities
                                                        ?.viewWorkflowHistory &&
                                                    community.metadata
                                                        ?.aiEnabled) ??
                                                false,
                                        ),
                                    );
                            } else return of(false);
                        }),
                    ),
                ),
            )
            .subscribe((value) => {
                if (value) {
                    this.bottomMenuService.addComponent(
                        {
                            type: AIFabComponent,
                        },
                        true,
                    );
                } else {
                    this.bottomMenuService.removeComponent(AIFabComponent);
                }
            });

        this.actions
            .pipe(ofType(AIActions.openGuide, AIActions.openContentGeneration))
            .subscribe((action) => {
                const components = this.bottomMenuService.getComponents();
                if (!componentsIncludeType(components, AIFabComponent)) {
                    this.bottomMenuService.addComponent(
                        {
                            type: AIFabComponent,
                        },
                        true,
                    );
                }

                this.store
                    .select(selectIsPanelOpen)
                    .pipe(
                        debounceTime(ANIMATION_DURATION_MS),
                        filter((value) => value === false),
                        take(1),
                    )
                    .subscribe(() => {
                        if (
                            !componentsIncludeType(components, AIFabComponent)
                        ) {
                            this.bottomMenuService.removeComponent(
                                AIFabComponent,
                            );
                        }
                    });

                this.store.dispatch(
                    AIActions.setActiveContext({
                        contextId:
                            action.type === AIActions.openContentGeneration.type
                                ? action.contextId
                                : -1,
                        contextType:
                            action.type === AIActions.openContentGeneration.type
                                ? AIContextType.CONTENT_GEN
                                : AIContextType.GUIDE,
                        workflowEnabled: false,
                        initialContentGeneration:
                            action.type === AIActions.openContentGeneration.type
                                ? action.initialContent
                                : null,
                    }),
                );

                this.store.dispatch(AIActions.toggleAIPanel({ isOpen: true }));
            });
    }
}
