<div
    *ngIf="{
        hoverError: hoverError$ | async,
        hoverRow: hoverRow$ | async,
        hoverSuccessIcon: hoverSuccessIcon$ | async,
    } as $"
    class="flex h-52 items-center justify-between"
    (mouseenter)="hoverRow$.next(true)"
    (mouseleave)="hoverRow$.next(false)"
>
    <div class="flex items-center space-x-8">
        <interacta-image
            classes="w-24 h-24"
            alt="{{ attachment.name }}'s icon"
            [src]="attachment.iconPath || ''"
        ></interacta-image>

        <span class="typo-sm line-clamp-1 break-all text-left">{{
            attachment.name
        }}</span>
    </div>

    <ng-container *ngIf="attachment.uploadProgress">
        <interacta-progress-loader
            *ngIf="attachment.uploadProgress < 100 && !$.hoverRow"
            [matTooltip]="attachment.uploadProgress + '%'"
            [progress]="attachment.uploadProgress"
        />
        <button
            *ngIf="attachment.uploadProgress < 100 && $.hoverRow"
            class="bg-surface-100 rounded-full"
            interacta-icon-button
            type="button"
            [icon]="'close'"
            [matTooltip]="'UPLOAD.CANCEL_UPLOAD' | translate"
            (click)="cancelUpload.emit()"
        ></button>
        <div
            *ngIf="attachment.uploadProgress === 100"
            (mouseenter)="hoverSuccessIcon$.next(true)"
            (mouseleave)="hoverSuccessIcon$.next(false)"
        >
            <interacta-progress-loader
                *ngIf="attachment.id == null"
                [matTooltip]="'UPLOAD.SAVING' | translate"
                [progress]="100"
            />

            <ng-container *ngIf="attachment.id != null">
                <interacta-icon
                    *ngIf="!$.hoverSuccessIcon || !attachment.post"
                    class="text-system-success h-24 w-24"
                    [icon]="'check'"
                ></interacta-icon>
                <a
                    *ngIf="attachment.post && $.hoverSuccessIcon"
                    interacta-icon-button
                    [bgColor]="'transparent'"
                    [icon]="'external-link'"
                    [matTooltip]="'UPLOAD.GOTO_POST' | translate"
                    [matTooltipPosition]="'left'"
                    [routerLink]="['/post', attachment.post.id]"
                    [shape]="'round'"
                ></a>
            </ng-container>
        </div>
    </ng-container>
    <div
        *ngIf="attachment.isLoadingError"
        (mouseenter)="hoverError$.next(true)"
        (mouseleave)="hoverError$.next(false)"
    >
        <button
            class="text-text-error"
            interacta-icon-button
            type="button"
            [icon]="$.hoverError ? 'retry' : 'warning'"
            [matTooltip]="'UPLOAD.UPLOAD_ERROR_RETRY' | translate"
            (click)="retryUpload.emit()"
        ></button>
    </div>
    <div *ngIf="attachment.isCanceled" class="typo-xs shrink-0">
        {{ 'UPLOAD.UPLOAD_CANCELED' | translate }}
    </div>
</div>
