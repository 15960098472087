@if (theme$ | async; as theme) {
    <div
        class="bottom-46 relative -right-10 flex size-56 items-center justify-center rounded-bl-full rounded-tl-full rounded-tr-full"
        [attr.role]="isOpen() ? 'dialog' : null"
        [ngClass]="{
            'gradient-border': !isOpen() && !hovering,
            'gradient-border-thin': !isOpen() && hovering,
            'gradient-border-dark': theme.mode === 'dark' && !isOpen(),
            'gradient-border-light': theme.mode === 'light' && !isOpen(),
            'origin-bottom-right scale-[1.7]': hovering && !isOpen(),
            'bg-surface-A bottom-[100vh] right-0 h-[100vh] w-[40vw] translate-x-0 translate-y-0 rounded-bl-none rounded-tl-none rounded-tr-none':
                isOpen(),
        }"
        [style]="
            'transition: all ' +
            ANIMATION_DURATION_MS +
            'ms,border .1s,border-radius .1s,border-color .1s;'
        "
        (mouseout)="hovering = false"
        (mouseover)="hovering = true"
    >
        @if (showButton()) {
            <button
                class="flex size-full items-center justify-center gap-x-4 rounded-full transition-all"
                type="button"
                [attr.aria-label]="'AI.ACCESS_AI_SECTION' | translate"
                [ngClass]="{
                    'scale-[0.7]': hovering && !isOpen(),
                }"
                (click)="toggleOpen(!isOpen())"
            >
                <interacta-image
                    alt="ai logo"
                    [classes]="'size-24'"
                    [loading]="'eager'"
                    [src]="
                        theme.mode === 'dark'
                            ? './assets/images-2.0/ai/ai-interacta-logo_dark.svg'
                            : './assets/images-2.0/ai/ai-interacta-logo_light.svg'
                    "
                ></interacta-image>
                <p
                    *ngIf="hovering"
                    class="typo-h5 text-text pointer-events-none"
                >
                    AI
                </p>
            </button>
        }
    </div>
}
