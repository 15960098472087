import { Type } from '@angular/core';
import { AIFabComponent } from '@modules/ai/components/ai-fab/ai-fab.component';
import { ChatPanelComponent } from '@modules/chat/components/chat-panel/chat-panel.component';
import { BehaviorSubject } from 'rxjs';

export type BottomMenuItemType = ChatPanelComponent | AIFabComponent;

export type BottomMenuItem = {
    type: Type<BottomMenuItemType>;
};

export class BottomMenuState {
    components$ = new BehaviorSubject<BottomMenuItem[]>([]);
}
