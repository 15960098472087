<section class="flex size-full flex-col gap-y-24">
    <div class="flex items-center gap-x-10">
        <button
            interacta-icon-button
            type="button"
            [attr.aria-label]="'BUTTON.LABEL_BUTTON_BACK' | translate"
            [icon]="'arrow-left'"
            [matTooltip]="'BUTTON.LABEL_BUTTON_BACK' | translate"
            (click)="backClicked.emit()"
        ></button>
        <p class="typo-sm-bold">
            {{ section | apply: getContentGenerationTypeLabel | translate }}
        </p>
    </div>
    <div
        *ngIf="generatedContent$ | async as generatedContent"
        class="flex w-full grow flex-col gap-y-24 overflow-y-auto"
    >
        @if (generatedContent.isFetching) {
            <div
                class="bg-surface-A h-100 typo-sm flex min-h-0 w-full shrink-0 gap-x-8 rounded-2xl px-8 py-12"
            >
                <interacta-image
                    alt=""
                    class="shrink-0"
                    [classes]="'w-36 h-24'"
                    [src]="
                        theme === 'light'
                            ? './assets/images-2.0/ai/ai-interacta-loader-animation.gif'
                            : './assets/images-2.0/ai/ai-interacta-loader-animation_D.gif'
                    "
                />
                <p class="flex grow flex-col gap-y-8">
                    <span
                        class="bg-surface-300 h-8 w-full animate-pulse rounded-3xl"
                    ></span>
                    <span
                        class="bg-surface-300 h-8 w-1/2 animate-pulse rounded-3xl"
                    ></span>
                    <span
                        class="bg-surface-300 h-8 w-2/3 animate-pulse rounded-3xl"
                    ></span>
                </p>
            </div>
        } @else if (generatedContent.hasErrors) {
            <interacta-ai-panel-content-generation-detail-card
                [canCollapse]="false"
                [content]="null"
                [hasErrors]="true"
                [like]="undefined"
                [title]="null"
                (retryClicked)="retry()"
            />
        } @else {
            @for (
                content of (generatedContent$ | async)?.contents ?? [];
                track $index
            ) {
                <interacta-ai-panel-content-generation-detail-card
                    [canCollapse]="
                        section === AIContentGenerationType.TONE_OF_VOICE
                    "
                    [content]="content.delta"
                    [like]="content.like"
                    [title]="
                        section === AIContentGenerationType.TONE_OF_VOICE &&
                        content.tone
                            ? (content.tone
                              | apply: getToneOfVoiceTypeLabel
                              | translate)
                            : null
                    "
                    (confirmClicked)="confirm(content)"
                    (sendFeedback)="setLike(content, $event)"
                    (sendFeedbackDetail)="sendFeedbackDetail(content, $event)"
                />
            }
        }
    </div>
</section>
