import { Injectable, Type } from '@angular/core';
import {
    BottomMenuItem,
    BottomMenuState,
} from '@modules/bottom-menu/models/bottom-menu-state.model';
import { Observable } from 'rxjs';
import { componentsIncludeType } from '../helpers/bottom-menu.utils';

@Injectable({
    providedIn: 'root',
})
export class BottomMenuService {
    private readonly state: BottomMenuState;

    constructor() {
        this.state = new BottomMenuState();
    }

    getComponentsStream(): Observable<BottomMenuItem[]> {
        return this.state.components$.asObservable();
    }

    getComponents(): BottomMenuItem[] {
        return this.state.components$.getValue();
    }

    removeComponent(component: Type<any>): void {
        this.state.components$.next([
            ...this.state.components$
                .getValue()
                .filter((c) => !(c.type === component)),
        ]);
    }

    addComponent(component: BottomMenuItem, prepend = false): void {
        if (
            componentsIncludeType(
                this.state.components$.getValue(),
                component.type,
            )
        )
            return;

        prepend
            ? this.state.components$.next([
                  component,
                  ...this.state.components$.getValue(),
              ])
            : this.state.components$.next([
                  ...this.state.components$.getValue(),
                  component,
              ]);
    }
}
