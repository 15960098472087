<ng-container *ngIf="tip$ | async as tip">
    <section
        *ngIf="tip"
        class="bg-surface-A flex flex-1 items-center gap-x-16 rounded-2xl p-16"
    >
        <interacta-image
            alt=""
            [classes]="'size-80'"
            [src]="tip.imageSrc"
        ></interacta-image>
        <div class="flex flex-col items-start gap-y-4">
            <p class="typo-sm-bold">
                {{ tip.title | translate }}
            </p>
            <p class="typo-sm">
                {{ tip.description | translate }}
            </p>
            <button
                class="text-text-primary typo-xs-bold-uppercase py-4"
                type="button"
                (click)="tipButtonClicked.emit()"
            >
                {{ tip.buttonLabel | translate }}
            </button>
        </div>
    </section>
</ng-container>
