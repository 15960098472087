import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { DiscoveryService } from '@modules/discovery/services/discovery.service';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-ai-message-attachment-chip',
    templateUrl: './ai-message-attachment-chip.component.html',
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIMessageAttachmentChipComponent implements OnInit {
    @Input({ required: true }) attachment!: IAttachment;
    @Input() messagesCount = 0;
    @Input() showThreadButton = true;
    @Input() hasErrors = false;

    @Output() attachmentClicked = new EventEmitter<void>();
    @Output() goToThread = new EventEmitter<void>();

    constructor(private discoveryService: DiscoveryService) {}

    ngOnInit(): void {
        this.discoveryService.openTip(
            { id: 'ai-attachments-threads' },
            {
                title: 'AI.ATTACHMENTS_THREADS_PROTIP_TITLE',
                message: 'AI.ATTACHMENTS_THREADS_PROTIP_SUBTITLE',
                image: 'person',
                tipActions: [
                    {
                        id: '',
                        label: 'AI.ATTACHMENTS_THREADS_PROTIP_BUTTON',
                        data: {},
                    },
                ],
            },
        );
    }
}
