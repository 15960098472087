<section class="flex flex-col gap-y-8">
    <p>{{ title }}</p>
    @for (attachment of attachments.attachments; track attachment.id) {
        <ng-container
            *ngIf="{
                selected:
                    { attachment, selectedAttachment } | apply: isSelected,
                hovering:
                    { attachment, hoveringAttachment } | apply: isHovering,
            } as $"
        >
            <button
                class="relative -mx-8 box-border flex items-start gap-x-8 rounded-xl border-4 p-4"
                type="button"
                [ngClass]="{
                    'bg-surface-primary-low-contrast border-border-primary':
                        $.selected,
                    'border-surface-A': !$.selected && theme === 'light',
                    'border-surface-C': !$.selected && theme === 'dark',
                }"
                (click)="selectAttachment.emit(attachment)"
                (mouseenter)="hoveringAttachment = attachment"
                (mouseleave)="hoveringAttachment = null"
            >
                <interacta-attachment-preview
                    class="self-center"
                    [attachment]="attachment"
                    [iconSize]="'small'"
                    [size]="'small'"
                />
                <section class="flex grow flex-col items-start overflow-hidden">
                    <interacta-inner-ellipses-text
                        class="typo-sm-bold text-text max-w-full"
                        [bgColor]="
                            $.selected
                                ? 'surface-primary-low-contrast'
                                : theme === 'light'
                                  ? 'surface-A'
                                  : 'surface-C'
                        "
                        [matTooltip]="attachment.name"
                        [text]="attachment.name"
                    />
                    <p class="typo-sm text-text overflow-ellipsis text-nowrap">
                        {{ attachment.creatorUser?.fullName }}
                    </p>
                    <interacta-creation-date
                        *ngIf="attachment.creationTimestamp"
                        class="typo-sm text-text-mid-contrast"
                        [date]="attachment.creationTimestamp"
                    />
                </section>
                <div
                    *ngIf="$.hovering"
                    class="absolute inset-0 rounded-lg"
                    @selectionOverlay
                    [ngClass]="{
                        'bg-transparent-primary ring-border-primary ring-4':
                            !$.hovering,
                        'bg-transparent-black-not-black-10': $.hovering,
                    }"
                ></div>
                @if ($.selected) {
                    <interacta-icon
                        class="bg-text-white text-text-primary border-surface-A border-1 relative mx-8 mt-8 flex size-24 items-center justify-center rounded-full"
                        [icon]="'check-circle-true'"
                    />
                }
                @if (!$.selected && $.hovering) {
                    <div
                        class="border-text-white relative mx-8 mt-8 size-24 shrink-0 rounded-full border-2 bg-transparent"
                    ></div>
                }
            </button>
            <ng-container
                *ngIf="
                    attachments.isLoading &&
                    attachments.pageTokenInfo.tag === 'firstLoading'
                "
            >
                <div
                    *ngFor="let _ of 2 | array"
                    class="bg-surface-300 h-44 w-full animate-pulse place-self-center rounded-lg"
                ></div>
            </ng-container>
            <interacta-separator *ngIf="!$last" [thickness]="'soft'" />
        </ng-container>
    }

    <interacta-load-more
        *ngIf="attachments.pageTokenInfo.tag === 'regularLoading'"
        class="self-center"
        [isLoading]="attachments.isLoading"
        [label]="'SHOW_MORE' | translate"
        [size]="'large'"
        (loadMore)="loadMore.emit()"
    />
</section>
