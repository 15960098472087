import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ConfirmDialogService } from '@interacta-shared/ui-common';
import * as PostApiActions from '@modules/communities/store/post/post-api.actions';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { AttachmentInputService } from '@modules/shared-v2/services/attachment-input.service';
import * as UploadActions from '@modules/upload-dialog/store/upload.actions';
import { UploadState } from '@modules/upload-dialog/store/upload.reducer';
import { getUploadFeature } from '@modules/upload-dialog/store/upload.selector';
import { UPLOAD_ATTACHMENT_INPUT_SERVICE } from '@modules/upload-dialog/upload-dialog.module';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import {
    distinctUntilKeyChanged,
    map,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

@Component({
    selector: 'interacta-upload-dialog',
    templateUrl: './upload-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDialogComponent implements OnInit, OnDestroy {
    state$: Observable<UploadState>;
    attachments$ = new BehaviorSubject<IAttachment[]>([]);
    notCanceledAttachmentsCount$: Observable<number>;
    completedUploads$: Observable<number>;
    private destroy$ = new Subject<void>();

    constructor(
        @Inject(UPLOAD_ATTACHMENT_INPUT_SERVICE)
        public attachmentInputService: AttachmentInputService,
        private store: Store,
        private actions: Actions,
        private confirmDialogService: ConfirmDialogService,
    ) {
        this.state$ = this.store.select(getUploadFeature);
        this.attachmentInputService.init({
            context: 'popup',
        });
        this.notCanceledAttachmentsCount$ = this.attachments$.pipe(
            map((list) => list.filter((i) => !i.isCanceled).length),
        );
        this.completedUploads$ = this.attachments$.pipe(
            map(
                (list) =>
                    list.filter(
                        (i) =>
                            !i.isPartial && !i.isLoadingError && !i.isCanceled,
                    ).length,
            ),
        );
    }

    ngOnInit(): void {
        this.subscribeToStartLoadingAttachment();
        this.subscribeToItemUpdates();

        this.subscribeToAddAttachmentSuccess();
        this.subscribeToAddAttachmentError();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    expand(): void {
        this.store.dispatch(UploadActions.expandDialog());
    }

    collapse(): void {
        this.store.dispatch(UploadActions.collapseDialog());
    }

    close(): void {
        this.attachments$.next([]);
        this.store.dispatch(UploadActions.closeDialog());
    }

    retryUpload(index: number, attachment: IAttachment): void {
        if (!attachment.fileItem) {
            throw new Error(
                "Can't retry upload for undefined attachment.fileItem ",
            );
        }
        const updateList = this.attachments$.getValue().map((i, idx) =>
            idx == index
                ? {
                      ...i,
                      markAsHandled: false,
                      fileItem: undefined,
                  }
                : i,
        );
        this.attachments$.next(updateList);
        this.attachmentInputService.startLoadingProcedure([
            attachment.fileItem,
        ]);
    }

    cancelUpload(attachment: IAttachment): void {
        this.cancelAttachments([attachment]);
    }

    async cancelNotYetLoadedUploads(): Promise<void> {
        const confirm = await this.confirmDialogService
            .open({
                title: 'UPLOAD.CANCEL_ALL_CONFIRM.TITLE',
                description: 'UPLOAD.CANCEL_ALL_CONFIRM.DESCRIPTION',
                confirm: 'UPLOAD.CANCEL_ALL_CONFIRM.CONFIRM',
                cancel: 'UPLOAD.CANCEL_ALL_CONFIRM.CANCEL',
                size: 'large',
            })
            .toPromise();

        if (confirm) {
            const attachments = this.attachments$.value.filter(
                (i) => i.isPartial,
            );
            this.cancelAttachments(attachments);
        }
    }

    private cancelAttachments(attachments: IAttachment[]): void {
        this.attachmentInputService.cancelAttachmentsUploading(attachments);
    }

    trackAttachment(_idx: number, attachment: IAttachment): number | File {
        return attachment.fileItem?._file || _idx;
    }

    private getAttachmentInError(
        oldList: IAttachment[],
        newItem: IAttachment,
    ): IAttachment | undefined {
        return oldList.find(
            (a) =>
                a.isLoadingError === true &&
                a.name === newItem.name &&
                a.markAsHandled === false,
        );
    }

    private updateAttachmentFromList(
        newItem: IAttachment,
        actualList: IAttachment[],
    ): IAttachment[] {
        return actualList.map((i) =>
            i.isPartial && i.name === newItem.name ? newItem : i,
        );
    }

    private subscribeToStartLoadingAttachment(): void {
        this.attachmentInputService.startLoadingAttachmentEvent$
            .pipe(
                tap(() => this.store.dispatch(UploadActions.openDialog())),
                tap((newAttachments) => {
                    this.store.dispatch(
                        UploadActions.updateSessionAttachmentsMap({
                            sessionId:
                                newAttachments[0].attachmentsUploadSessionId,
                            counter: newAttachments.length,
                        }),
                    );
                }),
                withLatestFrom(this.attachments$),
                takeUntil(this.destroy$),
            )
            .subscribe(([newAttachments, oldList]) => {
                const isReload =
                    newAttachments.length === 1 &&
                    !!this.getAttachmentInError(oldList, newAttachments[0]);

                let newList: IAttachment[] = [];
                if (isReload) {
                    const attachmentToReplace = this.getAttachmentInError(
                        oldList,
                        newAttachments[0],
                    );
                    newList = oldList.map((i) =>
                        i == attachmentToReplace ? newAttachments[0] : i,
                    );
                } else {
                    newList = [...oldList, ...newAttachments];
                }
                this.attachments$.next(newList);
            });
    }

    private subscribeToItemUpdates(): void {
        merge(
            this.attachmentInputService.singleAttachmentLoadedEvent$,
            this.attachmentInputService.progressItemEvent$.pipe(
                distinctUntilKeyChanged('uploadProgress'),
            ),
        )
            .pipe(
                withLatestFrom(this.attachments$),
                map(([newItem, actualList]) =>
                    this.updateAttachmentFromList(newItem, actualList),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe((list) => {
                this.attachments$.next(list);
            });
    }

    private subscribeToAddAttachmentSuccess(): void {
        this.actions
            .pipe(
                ofType(PostApiActions.addAttachmentsSuccess),
                withLatestFrom(this.attachments$),
                takeUntil(this.destroy$),
            )
            .subscribe(([{ attachments }, actualList]) => {
                const newList = actualList.map((actualItem) => {
                    const itemToUpdate = attachments.find(
                        (a) => a.contentRef === actualItem.contentRef,
                    );
                    return itemToUpdate
                        ? {
                              ...actualItem,
                              id: itemToUpdate.id,
                          }
                        : actualItem;
                });
                this.attachments$.next(newList);
            });
    }

    private subscribeToAddAttachmentError(): void {
        this.actions
            .pipe(
                ofType(PostApiActions.addAttachmentsError),
                withLatestFrom(this.attachments$),
                takeUntil(this.destroy$),
            )
            .subscribe(([{ attachments }, actualList]) => {
                const newList = actualList.map((actualItem) => {
                    const itemToUpdate = attachments.find(
                        (a) => a.contentRef === actualItem.contentRef,
                    );
                    return itemToUpdate
                        ? {
                              ...actualItem,
                              isLoadingError: true,
                              uploadProgress: undefined,
                          }
                        : actualItem;
                });
                this.attachments$.next(newList);
            });
    }
}
